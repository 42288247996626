// We're lying on the types here since this is web only and react native does not ship
// with support for things that we need.

import {
  Button,
  Logo,
  Text,
  XStack,
  YStack,
  IconButton,
  HamburgerIcon,
  HomeIcon,
  ShopIcon,
  BountiesIcon,
  SettingsIcon,
  useMedia,
  ShopifyIcon,
  LinkButton,
  Avatar,
  Link as LinkComponent,
} from '@bounty/creators-design-system'
import { Link } from 'solito/link'
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { useAuth } from '../../hooks'
import { useWebWrapper } from './WebWrapper'
import {
  BANNER_HEIGHT,
  MOBILE_BANNER_HEIGHT,
  MOBILE_NAV_HEIGHT,
  NAV_HEIGHT,
} from './WebConstants'
import { CurrentRewardsDocument } from '../../generated/backendGraphql'
import { useQuery } from '@apollo/client'
import { prettyCurrency } from '@bounty/utils'

export const positions = [
  {
    // Random percentage because color animations are broken and precise values mess up the
    // blur for the fixed nax bar
    y: '-100%',
  },
  {
    y: 0,
  },
]

const creatorsNavItemMap = {
  home: {
    icon: HomeIcon,
    name: 'Home',
    to: '/',
  },
  // messages: {
  //   icon: MessagesIcon,
  //   name: 'Messages',
  //   to: '/messages',
  // },
  shop: {
    icon: ShopIcon,
    name: 'Shop',
    to: '/shop',
  },
  bounties: {
    icon: BountiesIcon,
    name: 'Bounties',
    to: '/bounties',
  },
  settings: {
    icon: SettingsIcon,
    name: 'Settings',
    to: '/settings',
  },
}

const brandsNavItemMap = {
  home: {
    icon: null,
    name: 'Home',
    to: '/brands',
  },
  faqs: {
    icon: null,
    name: 'FAQs',
    to: '/brands/faqs',
  },
}

const buildBrandsNavItems = () => {
  return [brandsNavItemMap.home, brandsNavItemMap.faqs]
}

const buildCreatorsNavItems = (isAuthed: boolean) => {
  return isAuthed
    ? [
        creatorsNavItemMap.home,
        // creatorsNavItemMap.messages,
        creatorsNavItemMap.shop,
        creatorsNavItemMap.bounties,
        creatorsNavItemMap.settings,
      ]
    : [creatorsNavItemMap.home, creatorsNavItemMap.shop]
}

const NavItem = ({
  variant = 'hamburger',
  item: { icon: Icon, name, to },
}: {
  item: typeof creatorsNavItemMap['home'] | typeof brandsNavItemMap['home']
  variant: 'navbar' | 'hamburger'
}) => {
  const [{ showWebSwitcher }] = useWebWrapper()
  const router = useRouter()
  // TODO: This needs to be more ambitious with nested pages
  const isActive = router.pathname === to
  const color =
    showWebSwitcher && variant === 'navbar'
      ? isActive
        ? '$neutral.50'
        : '$whiteAlpha.700'
      : isActive
      ? '$primary.500'
      : '$neutral.500'
  return (
    <Link href={to}>
      <XStack space="$2" alignItems="center" data-testid={to}>
        {Icon ? <Icon weight="fill" size={28} color={color} /> : null}
        <Text fontWeight={'$semibold'} size="$lg" color={color}>
          {name}
        </Text>
      </XStack>
    </Link>
  )
}

const WebSwitcherItem = ({
  children,
  href,
  isActive,
}: {
  children: ReactNode
  href: string
  isActive: boolean
}) => {
  return (
    <Link href={href}>
      <YStack width={120} position="relative">
        <YStack
          className="web-switcher-item"
          backgroundColor={isActive ? '$primary.500' : '$primary.100'}
          width={120}
          height={MOBILE_BANNER_HEIGHT}
          $gtMd={{ height: BANNER_HEIGHT }}
        ></YStack>
        <Text
          width={120}
          className="web-switcher-item-text"
          color={isActive ? '$neutral.50' : '$primary.900'}
          textAlign="center"
          fontWeight={'$semibold'}
          size="$sm"
          $gtMd={{ size: '$md' }}
        >
          {children}
        </Text>
      </YStack>
    </Link>
  )
}

const WebSwitcher = ({ isCreatorsMode }: { isCreatorsMode: boolean }) => {
  return (
    <XStack
      zIndex={100}
      height={MOBILE_BANNER_HEIGHT}
      backgroundColor="$primary.100"
      pl={'$4'}
      $gtMd={{ pl: 78, height: BANNER_HEIGHT }}
    >
      <WebSwitcherItem href="/" isActive={isCreatorsMode}>
        For Creators
      </WebSwitcherItem>
      <WebSwitcherItem href="/brands" isActive={isCreatorsMode === false}>
        For Brands
      </WebSwitcherItem>
    </XStack>
  )
}

const WebWalletButton = ({ showWebSwitcher }: { showWebSwitcher: boolean }) => {
  const { data: currentRewards } = useQuery(CurrentRewardsDocument)

  return (
    <Link href="/wallet/overview">
      <Button
        variant={showWebSwitcher ? 'outline' : 'solid'}
        colorScheme={showWebSwitcher ? 'whiteAlpha' : 'primary'}
        borderColor={'$neutral.50'}
        borderRadius={'18px'}
        size="$md"
        buttonTextProps={{
          color: '$neutral.50',
        }}
        event="Web Navigation Creators Wallet Button Clicked"
        $gtMd={{ size: '$md' }}
      >
        {prettyCurrency(
          (currentRewards?.userPaymentSummary?.pendingPayments ?? 0) +
            (currentRewards?.userPaymentSummary?.availableBalance ?? 0),
          {
            precision: 2,
          },
        )}
        <Avatar
          ml="$2"
          size="$7"
          src={currentRewards?.tikTokProfile?.profileImage ?? undefined}
        />
      </Button>
    </Link>
  )
}

export const WebNavigation = () => {
  const router = useRouter()
  const [{ isAuthed, isInitializing }] = useAuth()
  const [{ showWebSwitcher, isCreatorsMode }] = useWebWrapper()
  const { gtMd } = useMedia()
  const [isNavActive, setIsNavActive] = useState(false)
  const position = isNavActive ? positions[1] : positions[0]
  const navItems = isCreatorsMode
    ? buildCreatorsNavItems(isAuthed)
    : buildBrandsNavItems()

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setIsNavActive(false)
    })

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.events.off('routeChangeComplete', () => {})
    }
  }, [router, setIsNavActive])

  if (isInitializing) return null

  return (
    <YStack
      position="absolute"
      left={0}
      right={0}
      className={showWebSwitcher ? '' : 'web-navigation-container'}
    >
      {/* <YStack>
        <Text
          color="black"
          size="$sm"
          fontWeight={'$semibold'}
          $gtSm={{ display: 'flex' }}
          backgroundColor={'rgb(254, 198, 25)'}
        >
          <strong>Announcement:</strong> {'  '}Bounty acquired by leading
          influencer marketing company, gen.video!{'  '}
          <LinkComponent
            event="announcement"
            rel="noreferrer"
            target="_blank"
            href="https://www.gen.video/post/a-message-from-our-ceo-expanding-creator-commerce-solutions-with-partnrup-and-bounty-acquisition"
          >
            Read more
          </LinkComponent>
        </Text>
      </YStack> */}
      {showWebSwitcher && <WebSwitcher isCreatorsMode={isCreatorsMode} />}
      <XStack
        height={MOBILE_NAV_HEIGHT}
        className={`web-navigation ${
          showWebSwitcher ? '' : 'web-navigation-blur'
        }`}
        borderBottomColor={'$neutral.200'}
        borderBottomWidth={showWebSwitcher ? 0 : 1}
        $gtMd={{ height: NAV_HEIGHT }}
        backgroundColor={
          showWebSwitcher
            ? isCreatorsMode
              ? '$primary.500'
              : isNavActive
              ? '$primary.500'
              : 'transparent'
            : 'rgba(255, 255, 255, 0.75)'
        }
      >
        <XStack
          justifyContent="flex-start"
          flex={1}
          flexBasis={0}
          $gtMd={{ display: 'none' }}
        >
          <IconButton
            size="$md"
            p="0"
            event="Mobile Web Navigation Clicked"
            backgroundColor={'transparent'}
            onPress={() => setIsNavActive((x) => !x)}
            hoverStyle={{ backgroundColor: 'transparent' }}
            focusStyle={{ backgroundColor: 'transparent' }}
            icon={HamburgerIcon}
            iconProps={{
              color: showWebSwitcher ? '$whiteAlpha.700' : '$neutral.600',
              size: 32,
            }}
          />
        </XStack>
        {/* I could be crazy but with the shopify button and top nav center doesn't look center on
        small devices. When it gets bigger I notice so I actually center it right here. */}
        <XStack
          flex={1}
          flexBasis={0}
          justifyContent="center"
          $gtMd={{ justifyContent: 'flex-start' }}
          data-testid="web-navigation-home-logo-link"
        >
          <Link href={isCreatorsMode ? '/' : '/brands'}>
            <Logo
              size={120}
              color={showWebSwitcher ? '$neutral.50' : '$neutral.900'}
            />
          </Link>
        </XStack>
        {isCreatorsMode === false && (
          <XStack
            flex={1}
            flexBasis={0}
            display="none"
            space="$4"
            justifyContent="center"
            $gtMd={{ display: 'flex' }}
          >
            {navItems.map((item) => {
              return <NavItem variant="navbar" key={item.name} item={item} />
            })}
          </XStack>
        )}
        <XStack
          justifyContent="flex-end"
          flex={1}
          flexBasis={0}
          space="$6"
          alignItems="center"
        >
          {isCreatorsMode === true && (
            <XStack
              alignItems="center"
              display="none"
              space="$6"
              $gtMd={{ display: 'flex' }}
            >
              {navItems.map((item) => {
                return <NavItem variant="navbar" key={item.name} item={item} />
              })}
            </XStack>
          )}
          {isCreatorsMode === false ? (
            <LinkButton
              variant={showWebSwitcher ? 'outline' : 'solid'}
              colorScheme={showWebSwitcher ? 'whiteAlpha' : 'primary'}
              borderColor={'$neutral.50'}
              event={'Web Navigation Install on Shopify Button Clicked'}
              buttonTextProps={{
                color: '$neutral.50',
              }}
              size="$sm"
              $gtMd={{ size: '$md' }}
              href="https://form.typeform.com/to/i03A8JAw"
              target="_blank"
            >
              <Text mr="$2" lineHeight={1}>
                <ShopifyIcon size={16} color={'$neutral.50'} />
              </Text>
              <Text
                color="$neutral.50"
                display="none"
                whiteSpace="break-spaces"
                size="$sm"
                fontWeight={'$semibold'}
                $gtSm={{ display: 'flex' }}
              >
                Schedule a Demo
              </Text>
            </LinkButton>
          ) : isAuthed === false ? (
            <Link href="/sign-up">
              <Button
                variant={showWebSwitcher ? 'outline' : 'solid'}
                colorScheme={showWebSwitcher ? 'whiteAlpha' : 'primary'}
                borderColor={'$neutral.50'}
                buttonTextProps={{
                  color: '$neutral.50',
                }}
                event="Web Navigation Creators Sign In Button Clicked"
                size="$sm"
                $gtMd={{ size: '$md' }}
              >
                Sign In
              </Button>
            </Link>
          ) : (
            !gtMd &&
            isAuthed && <WebWalletButton showWebSwitcher={showWebSwitcher} />
          )}
        </XStack>
      </XStack>
      <XStack
        position="absolute"
        left="0"
        right="0"
        animation={'nav'}
        animateOnly={['y']}
        pt={MOBILE_NAV_HEIGHT + (showWebSwitcher ? MOBILE_BANNER_HEIGHT : 0)}
        $gtMd={{ pt: NAV_HEIGHT + (showWebSwitcher ? BANNER_HEIGHT : 0) }}
        backgroundColor={'$neutral.50'}
        height={`100vh`}
        zIndex={10}
        {...position}
      >
        <YStack px="$4" py="$6" space="$6">
          {navItems.map((item) => {
            return <NavItem variant="hamburger" key={item.name} item={item} />
          })}
        </YStack>
      </XStack>
    </YStack>
  )
}
